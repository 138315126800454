import { patterns } from './constants';

export const getRandomPattern = () => {
  const randomRange = (min, max) => ~~(Math.random() * (max - min + 1)) + min;
  const index = randomRange(0, patterns.length - 1);
  return patterns[index];
};


const getPatternByOffset = (currentName, offset) => {
  const currentIndex = patterns.findIndex(p => p.name === currentName);
  const nextIndex = (currentIndex + offset) % patterns.length;
  return patterns[nextIndex];
}

export const getNextPattern = currentName => getPatternByOffset(currentName, 1);
export const getPrevPattern = currentName => getPatternByOffset(currentName, -1);

export const getPatternFromUrl = (pathname) => {
  const key = pathname.substring(1);
  const pattern = patterns.find(p => p.className === key);
  return pattern;
}
