import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Slider from 'rc-slider';
import ReactGA from 'react-ga';
import 'rc-slider/assets/index.css';
import './index.scss';

let tSizeEventId;
const UIControls = ({ onSizeChange = f => f, size, codePenUrl, palette = null }) => {
  const sizeTarget = useRef(null);
  return (
    <div className="ui-controls-component" ref={sizeTarget} style={{ '--color1': palette[1], '--color2': palette[0] }}>
      <div className="slider-wrapper">
        <div className="label">
          Size
        </div>
        <Slider 
          min={ 4 } 
          max={ 50 } 
          defaultValue={ size } 
          onChange={ val => {
            clearTimeout(tSizeEventId);
            tSizeEventId = setTimeout(() => {
              ReactGA.event({
                category: 'User',
                action: 'Size change',
                label: 'Size',
                value: val,
              });
            },2000);
            onSizeChange(val);
           } } 
          railStyle={{ 'backgroundColor': palette[1], 'opacity': '0.5' }}
          handleStyle={{ 'backgroundColor': '#FFFFFF', 'borderColor': '#FFFFFF', 'width': '14px', 'height': '14px', 'marginTop': '-5px', 'boxShadow': '0 0 0 4px' + palette[0] }}
          trackStyle={{ 'backgroundColor': palette[0] }}
        />
      </div>

      <Button
        variant="primary"
        href={ codePenUrl }
        target="_blank"
        onClick={ () => {
          ReactGA.event({
            category: 'User',
            action: 'Open pattern in CodePen',
            label: codePenUrl,
            value: 1,
          });
        }}
      >
        Open in CodePen
      </Button>
    </div>
  );
};

export default UIControls;