import React, { useState, useEffect } from 'react';
import Title from './components/Title';
import CSSPattern from './components/CSSPattern';
import UIControls from './components/UIControls';
import Swipe from 'react-easy-swipe';
import {
  getRandomPattern,
  getNextPattern,
  getPrevPattern,
  getPatternFromUrl,
} from './utils/helpers';
import {
  DEFAULT_SIZE,
  DEFAULT_PALETTE,
  palettes
} from './utils/constants';
import './App.css';

let tId;
const getInitialSize = () => Number(localStorage.getItem('size') || DEFAULT_SIZE);
const getInitialPalette = () => localStorage.getItem('palette') || DEFAULT_PALETTE;
const  AppLayout = ({ location, history }) => {
  // gets initial pattern from url.
  const pattern = getPatternFromUrl(location.pathname);
  const [size, setSize] = useState(getInitialSize());
  const [palette, setPalette] = useState(getInitialPalette());
  
  const [overlayClass, setOverlayClass] = useState('');
  const handleSizeChange = value => {
    localStorage.setItem('size', value);
    setSize(value);
  };

  const navigateToPatternUrl = url => {
    if (tId) {
      clearTimeout(tId);
    }
    localStorage.setItem('palette', (Number(palette) + 1) % palettes.length );
    setOverlayClass('cover');
    tId = setTimeout(() => history.push(url), 300);
  }

  const navigateToNextPattern = () => {
    navigateToPatternUrl(`/${getNextPattern(pattern.name).className}`);
  };

  const navigateToPrevPattern = () => {
    navigateToPatternUrl(`/${getPrevPattern(pattern.name).className}`);
  }

  // next pattern on Space press
  const _handleKeypress = (evt) => {
    if (evt.code === 'Space') {
      navigateToNextPattern();
    }
  };
  const _fullscreen = () => {
    document.documentElement.requestFullscreen();
  }

  // on mount
  useEffect(() => {
    // subscribes listeners (and return the unsubscribe method)
    document.addEventListener('keypress', _handleKeypress);
    // document.body.addEventListener('click', _fullscreen);
    return () => {
      document.removeEventListener('keypress', _handleKeypress);
      // document.body.removeEventListener('click', _fullscreen);
    };
  }, []);
  
  // if no pattern is in url we take a random one.
  if (location.pathname === '/') {
    history.push(`/${getRandomPattern().className}`);
    return null;
  }
  return (
    <Swipe
      onSwipeLeft={ navigateToPrevPattern }
      onSwipeRight={ navigateToNextPattern }
      tolerance = { 50 }
    >
      <div className={`overlay ${overlayClass}`} style={{ '--color1': palettes[palette][0] }} />
      <div className="App">
        <Title>{ pattern.name }</Title>
        <CSSPattern name={ pattern.className } size={ size } palette={ palettes[palette] } />
        <UIControls onPaletteChange={f => f} size={ size } palette={ palettes[palette] } onSizeChange={ handleSizeChange } codePenUrl={ pattern.codeUrl } />
      </div>
    </Swipe>
  );
}

export default AppLayout;
