import React from 'react';
import './index.scss';

const CSSPattern = ({ name, primaryColor = 'red', secondaryColor = 'green', size, palette = null }) => {
  const styleObj = {};
  styleObj['--unit'] = `${size}px`;
  if (palette !== null) {
    styleObj['--color1'] = palette[0];
    styleObj['--color2'] = palette[1];
    styleObj['--color3'] = palette[2];
  }
  return (
    <div
      style={ styleObj }
      className={ `css-pattern-component pattern pattern${name}` }
    />
  );
};

export default CSSPattern;
