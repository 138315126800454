export const DEFAULT_SIZE = 10;
export const DEFAULT_PALETTE = 0;
export const patterns = [
  {
    name: 'Vertical stripes',
    className: '__stripes-vertical',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/BaaowOB',
  },
  {
    name: 'Horizontal stripes',
    className: '__stripes-horizontal',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/WNNQZYr',
  },
  {
    name: 'Oblique stripes',
    className: '__stripes-oblique',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYpzMB',
  },
  {
    name: 'Centered radial',
    className: '__radial-center',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/dyyYVLQ',
  },
  {
    name: 'Cornered radial',
    className: '__radial-corner',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/zYYvEQR',
  },
  {
    name: 'Radial circles',
    className: '__radial-circles',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/MWWaEMo',
  },
  {
    name: 'Polka dots',
    className: '__polka-dots',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/ExxVbYL',
  },
  {
    name: 'Net',
    className: '__net',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/jOOrebK',
  },
  {
    name: 'Crosses',
    className: '__crosses',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/dyyXgGG',
  },
  {
    name: 'Sidewalk',
    className: '__sidewalk',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/MWWePKZ',
  },
  {
    name: 'Checkerboard',
    className: '__checkerboard',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/wvvWYGp',
  },
  {
    name: 'Interference',
    className: '__interference',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/mddEzEW',
  },
  {
    name: 'Zig Zag',
    className: '__zigzag',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/poobxbx',
  },
  {
    name: 'Blocks',
    className: '__blocks',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/vYYKVXy',
  },
  {
    name: 'Stars',
    className: '__stars',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/NWWrORY',
  },
  {
    name: 'Tulips',
    className: '__tulips',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/xxxOyRb',
  },
  {
    name: 'Spurs',
    className: '__spurs',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/bGGemBv',
  },
  {
    name: 'Arrows',
    className: '__arrows',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/ExxydZY',
  },
  {
    name: 'Triangles',
    className: '__triangles',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/bGGemgv',
  },
  {
    name: 'Small triangles',
    className: '__triangles-small',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/JjjKmWd',
  },
  {
    name: 'Tilted checkerboard',
    className: '__checkerboard-tilt',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/jOOreBv',
  },
  {
    name: 'Chevrons',
    className: '__chevrons',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/oNNLaWL',
  },
  {
    name: 'Hexagons',
    className: '__hexagons',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/BaazqRY',
  },
  {
    name: 'Shadows',
    className: '__shadows',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/abbZRwz',
  },
  {
    name: 'Ripples',
    className: '__ripples',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/GRRqYEv',
  },
  {
    name: 'Halves',
    className: '__halves',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/abbZRyz',
  },
  {
    name: 'Benidorm',
    className: '__benidorm',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYRQQG',
  },
  {
    name: 'Vitreaux',
    className: '__vitreaux',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/bGGwLLv',
  },
  {
    name: 'Moons',
    className: '__moons',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/jOOMZZv',
  },
  {
    name: 'Half circles',
    className: '__half-circles',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/eYYdVVo',
  },
  {
    name: 'Bowel',
    className: '__bowel',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/xxxEYWb',
  },
  {
    name: 'Thorns',
    className: '__thorns',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/YzzGeap',
  },
  {
    name: 'Fancy dots',
    className: '__fancy-dots',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/bGGwLvv',
  },
  {
    name: 'Lines',
    className: '__lines',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/yLLavKG',
  },
  {
    name: 'Spirals',
    className: '__spirals',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYRQmG',
  },
  {
    name: 'Crisscross',
    className: '__crisscross',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/RwwGQyp',
  },
  {
    name: 'Snakes',
    className: '__snakes',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/NWWRyMM',
  },
  {
    name: 'Circles',
    className: '__circles',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/XWWjZqv',
  },
  {
    name: 'Semicircles',
    className: '__semicircles',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/YzzGevN',
  },
  {
    name: 'Chains',
    className: '__chains',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/mddrXKw',
  },
  {
    name: 'Even circles',
    className: '__even-circles',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/BaaLYVY',
  },
  {
    name: 'Back and forth',
    className: '__back-and-forth',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/pooEaKq',
  },
  {
    name: 'Arrowhead',
    className: '__arrowhead',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/ExxgQpx',
  },
  {
    name: 'Interlaced',
    className: '__interlaced',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYRQBN',
  },
  {
    name: 'Drop',
    className: '__drop',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/YzzGejN',
  },
  {
    name: 'Sprouts',
    className: '__sprouts',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/gOOwvjG',
  },
  {
    name: 'Eyes',
    className: '__eyes',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/QWWKQBV',
  },
  {
    name: 'Fish',
    className: '__fish',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/eYYdVja',
  },
  {
    name: 'Spinner',
    className: '__spinner',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/xxxEYaG',
  },
  {
    name: 'Blades',
    className: '__blades',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/VwwKQGm',
  },
  {
    name: 'Concentric',
    className: '__concentric',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/GRRjQXv',
  },
  {
    name: 'Corkscrew',
    className: '__corkscrew',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/pooEmqq',
  },
  {
    name: 'Optical',
    className: '__optical',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/XWWjwov',
  },
  {
    name: 'Cylinder',
    className: '__cylinder',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/YzzGbBN',
  },
  {
    name: 'Waves',
    className: '__waves',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/NWWRVoz',
  },
  {
    name: 'Distort',
    className: '__distort',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/rNNMgPX',
  },
  {
    name: 'Studs',
    className: '__studs',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/JjjRqzG',
  },
  {
    name: 'Circuit',
    className: '__circuit',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/MWWjdxR',
  },
  {
    name: 'Cloth',
    className: '__cloth',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/vYYXwMm',
  },
  {
    name: 'Burst',
    className: '__burst',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/yLLaWrw',
  },
  {
    name: 'Lava',
    className: '__lava',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/oNNzRRz',
  },
  {
    name: 'Crops',
    className: '__crops',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/OJJRYYQ',
  },
  {
    name: 'Mountains',
    className: '__mountains',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/rNNMgEB',
  },
  {
    name: 'Pyramids',
    className: '__pyramids',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/GRRjabO',
  },
  {
    name: 'Infinite drops',
    className: '__infinite-drops',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/ZEEpNdd',
  },
  {
    name: 'People on tables',
    className: '__people-on-tables',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/VwwKOop',
  },
  {
    name: 'Mixed',
    className: '__mixed',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/WNNGBVa',
  },
  {
    name: 'Brackets',
    className: '__brackets',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYRKPR',
  },
  {
    name: 'Catupecu',
    className: '__catupecu',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/PooGrYQ',
  },
  {
    name: '45 degrees',
    className: '__45-degrees',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/yLLadBr',
  },
  {
    name: 'The game',
    className: '__the-game',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/qBBazBa',
  },
  {
    name: 'Pills',
    className: '__pills',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/RwwGzwL',
  },
  {
    name: 'Knot',
    className: '__knot',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/QWWKXWz',
  },
  {
    name: 'Stitches',
    className: '__stitches',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/XWWjLJJ',
  },
  {
    name: 'Parker',
    className: '__parker',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYRKEb',
  },
  {
    name: 'Carpet',
    className: '__carpet',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/BaaLgyV',
  },
  {
    name: 'Boxed',
    className: '__boxed',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/zYYKVGG',
  },
  {
    name: 'Martha',
    className: '__martha',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/oNNzrXB',
  },
  {
    name: 'Cubes',
    className: '__cubes',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/bGGwPdx',
  },
  {
    name: 'Ninja',
    className: '__ninja',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/oNNzrjZ',
  },
  {
    name: 'Sunsets',
    className: '__sunsets',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/MWWpaNg',
  },
  {
    name: 'Combs',
    className: '__combs',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/xxxqwvE',
  },
  {
    name: 'Patchwork',
    className: '__patchwork',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/mddWeNX',
  },
  {
    name: 'Scratches',
    className: '__scratches',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/NWWpGQe',
  },
  {
    name: 'Bookmarks',
    className: '__bookmarks',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/ZEEeQzE',
  },
  {
    name: 'Lightning',
    className: '__lightning',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYWGPW',
  },
  {
    name: 'Bowties',
    className: '__bowties',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/mddWVbX',
  },
  {
    name: 'Unstable',
    className: '__unstable',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/QWWpyLo',
  },
  {
    name: 'Slanted',
    className: '__slanted',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/yLLMeBm',
  },
  {
    name: 'Zipper',
    className: '__zipper',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/LYYWGYy',
  },
  {
    name: 'Barbed wire',
    className: '__barbed-wire',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/NWWpxWo',
  },
  {
    name: 'Fibonacci',
    className: '__fibonacci',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/ZEEeQYY',
  },
  {
    name: 'Small shadows',
    className: '__small-shadows',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/JjjWGoE',
  },
  {
    name: 'Mammatus',
    className: '__mammatus',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/GRRWogd',
  },
  {
    name: 'Yin yang',
    className: '__yin-yang',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/WNNprbm',
  },
  {
    name: 'Another brick',
    className: '__another-brick',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/ZEEeQGY',
  },
  {
    name: 'Lanterns',
    className: '__lanterns',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/mddWVJx',
  },
  {
    name: 'Chocolate rain',
    className: '__chocolate-rain',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/KKKWVpj',
  },
  {
    name: 'Puzzle',
    className: '__puzzle',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/JjjWGYW',
  },
  {
    name: 'Flower power',
    className: '__flower-power',
    codeUrl: 'https://codepen.io/100-css-patterns/pen/mddWVeL',
  }
];

export const palettes =[
  ['#3D0A75', '#3C4DE6', 'transparent'],
  ['#41CFED', '#8AF5E7', 'transparent'],
  ['#9EB25D', '#F1DB4B', 'transparent'],
  ['#946846', '#BAAB68', 'transparent'],
  ['#FF8A17', '#FFAF1C', 'transparent'],
  ['#9B3872', '#F41352', 'transparent'],
  ['#474B26', '#63BA98', 'transparent'],
  ['#5F4C66', '#A8AEC5', 'transparent'],
  ['#907AFF', '#BAA0EE', 'transparent'],
  ['#B85A37', '#E49f75', 'transparent'],
  ['#6271B1', '#58AEF1', 'transparent'],
];
