
import React from 'react';
import { BrowserRouter as Router, Route /*, Link*/ } from 'react-router-dom';
import AppLayout from './AppLayout';
import { patterns } from './utils/constants';
import ReactGA from 'react-ga';

const app = () =>
  <Router>
    <Route
      exact
      path="/"
      render={ props => <AppLayout { ...props } /> }
    />
    {
      patterns.map(p => (
        <Route
          key={ p.className }
          exact
          path={ `/${p.className}` }
          render={ props => {
            ReactGA.pageview(`/${p.className}`);
            return <AppLayout { ...props } />
          } }
        />
      ))
    }
  </Router>
;

export default app;

